.external-product-modal {
    width: 100%;
}

@media only screen and (max-width: 768px), (max-height: 600px) {
    .external-product-modal {
        padding: 40px;
        box-sizing: border-box;
    }
}

.external-product-modal-title {
    margin: 0 0 20px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.external-product-modal-description {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.external-product-modal-title .bold, .external-product-modal-description .bold {
    font-weight: 700;
}

.external-product-modal-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .external-product-modal-content {
        display: block;
        justify-content: unset;
        align-content: unset;
        align-items: unset;
    }
}

.external-product-modal-left-panel {
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .external-product-modal-left-panel {
        width: 100%;
    }
}

.external-product-modal-details {
    width: 100%;
}

.external-product-modal-details-icon-container {
    margin: 0 auto 8px auto;
    width: 40px;
}

.external-product-modal-details-title {
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
}

.external-product-modal-details-info-container {
    width: 75%;
    overflow: auto;
    margin: 15px auto 0 auto;
}

.external-product-modal-details-info {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin: 10px 0;
}

.external-product-modal-details-info-icon {
    width: 40px;
    margin-right: 10px;
}

.external-product-modal-details-info-message {
    margin: 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
}

.external-product-modal-qr-guide-container {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.external-product-modal-right-panel {
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .external-product-modal-right-panel {
        width: 100%;
    }
}

.external-product-modal-qr-image-container {
    width: auto;
    margin: 20px;
}

@media only screen and (max-width: 768px) {
    .external-product-modal-qr-image-container {
        margin: 20px auto 40px auto;
    }
}

.external-product-modal-qr-image-wrapper {
    width: 200px;
    position: relative;
    margin: 0 auto;
}

.external-product-modal-qr-image-canvas {
    display: block;
    margin: 0 auto;
    position: relative;
    z-index: 0;
}

.external-product-modal-qr-image-loader-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 20%;
    background: rgba(0, 0, 0, 0.7);
}

.external-product-modal-qr-image-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.external-product-modal-qr-image-generator {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 20%;
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
}

.external-product-modal-qr-image-generator-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    text-align: center;
    width: 90%;
}

.external-product-modal-qr-image-description {
    margin: 15px 0 0 0;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    text-align: center;
}

.external-product-modal-qr-image-description.error {
    color: #CD5C5C;
}

.external-product-modal-error {
    width: 450px;
    max-width: 100%;
    margin: 0 auto 50px auto;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    padding: 0 30px;
    box-sizing: border-box;
}

.external-product-modal-actions {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.external-product-modal-action {
    margin: 10px;
}