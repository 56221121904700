.product-details-sidebar-tab {
    height: 100%;
    color: #B2B2B2;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    margin-right: 20px;
    padding-bottom: 10px;
    box-sizing: border-box;
    cursor: pointer;
    transition: color 0.5s ease, border-bottom 0.5s ease;
}

.product-details-sidebar-tab:last-child {
    margin-right: 0;
}