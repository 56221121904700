.config-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9997;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: height 0.2s ease;
    overflow: hidden;
}

.config-overlay-timer {
    padding: 20px;
    box-sizing: border-box;
    width: 400px;
    max-width: 100%;
    color: #FFFFFF;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.261172);
}

.config-overlay-panel {
    position: relative;
    width: 500px;
    max-width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0.5);
    padding: 25px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.261172);
}

.config-overlay-panel-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    background: #CD5C5C;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    box-shadow: 1px 2px 14px rgba(0, 0, 0, 1);
    transition: transform 0.5s ease, filter 0.5s ease, background 0.5s ease;
}

.config-overlay-panel-close-button:hover {
    transform: scale(1.1);
    filter: brightness(95%);
}

.config-overlay-panel-close-button-icon {
    display: block;
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 26px;
    font-weight: bold;
    line-height: 30px;
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
    transition: color 0.5s ease;
}

.config-overlay-panel-title {
    color: #FFFFFF;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    margin: 0;
}

.config-overlay-panel-option {
    width: 100%;
    margin-top: 30px;
}

.config-overlay-panel-option-label {
    display: block;
    color: #FFFFFF;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    margin: 0 0 10px 10px;
}

.config-overlay-panel-buttons {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}