.product-tag {
    width: auto;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    transition: color 0.5s ease, border 0.5s ease, background 0.5s ease;
}

.product-tag:last-child {
    margin-right: 0 !important;
}

.product-tag-icon {
    margin-right: 7px;
}

.product-tag-label {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    transition: color 0.5s ease;
}