.config {
    padding: 20px;
    box-sizing: border-box;
}

.config-wrapper {
    width: 800px;
    max-width: 100%;
    margin: 50px auto;
}

.config-client-logo {
    width: 100%;
}

.config-section-image-container {
    width: 300px;
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin: 30px auto;
}

.config-section-image {
    width: 100%;
}

.config-options {
    width: 400px;
    height: 320px;
    max-width: 100%;
    margin: 100px auto;
    transition: margin 0.5s ease;
    position: relative;
}

@media only screen and (max-height: 1000px) {
    .config-options {
        margin: 20px auto;
    }
}

.config-options-wrapper {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.config-option {
    margin: 0 0 50px 0;
    transition: margin 0.5s ease;
}

@media only screen and (max-height: 1000px) {
    .config-option {
        margin: 0 0 20px 0;
    }
}

.config-option:last-child {
    margin-bottom: 0;
}

.config-error {
    width: 100%;
    max-width: 100%;
    color: #CD5C5C;
    margin-top: 10px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 15px;
    text-align: center;
}

.config-actions {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.config-action {
    width: auto;
}