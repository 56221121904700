.budget {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    letter-spacing: 3px;
}

.budget-title {
    width: 100%;
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 25px;
    font-weight: 900;
    margin: 10px 0;
}

.budget-id {
    width: 100%;
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 22px;
    font-weight: 900;
    margin: 10px 0;
}

.budget-logo-container {
    width: 100%;
    text-align: center;
    margin: 10px 0;
}

.budget-logo {
    width: 100%;
    max-width: 300px;
    height: auto;
}

.budget-subtitle {
    width: 100%;
    text-align: left;
    font-family: Tahoma, sans-serif;
    font-size: 20px;
    font-weight: 900;
    margin: 30px 0 10px 0;
}

.budget-info-item {
    width: 100%;
    text-align: left;
    font-family: monospace;
    font-size: 18px;
    font-weight: 900;
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
}

.budget-info-item-label {
    width: 25%;
    font-weight: 900;
}

.budget-info-item-value {
    width: 75%;
    text-transform: uppercase;
}

.budget-date {
    width: 100%;
    text-align: left;
    font-family: Tahoma, sans-serif;
    font-size: 20px;
    font-weight: 900;
    margin: 30px 0 0 0;
}

.budget-additional-instructions {
    width: 100%;
    text-align: left;
    font-family: Tahoma, sans-serif;
    font-size: 15px;
    font-weight: 900;
    margin: 10px 0 30px 0;
}

.budget-items {
    width: 100%;
    margin: 10px 0;
    padding-bottom: 20px;
    border-bottom: solid 3px #000000;
}

.budget-items-table {
    width: 100%;
    padding: 0 0 20px 0;
    box-sizing: border-box;
    font-family: monospace;
    font-weight: 900;
    font-size: 18px;
    border-spacing: 0;
    border-collapse: collapse;
}

.budget-items-table th {
    text-transform: uppercase;
    border-top: solid 3px #000000;
    border-bottom: solid 3px #000000;
    padding: 5px 2px 18px 2px;
    box-sizing: border-box;
}

.budget-item-cell {
    padding: 2px 2px;
    box-sizing: border-box;
}

.budget-item-cell.top-padding {
    padding-top: 5px;
}

.budget-item-cell.bottom-padding {
    padding-bottom: 20px;
}

.budget-total-cell {
    padding: 2px;
}

.budget-total-cell.border {
    border-top: solid 3px #000000;
    padding-top: 20px;
}

.budget-total-cell.bold {
    font-weight: 900;
}

.budget-expiration-message {
    width: 100%;
    text-align: center;
    margin: 30px 0 0 0;
    font-family: Tahoma, sans-serif;
    font-size: 15px;
    font-weight: 900;
    color: #CD5C5C;
}