.search-modal {
    width: 100%;
}

@media only screen and (max-width: 768px), (max-height: 600px) {
    .search-modal {
        padding: 40px;
        box-sizing: border-box;
    }
}

.search-modal-title {
    margin: 0 0 20px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.search-modal-description {
    margin: 0 0 20px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.search-modal-input {
    width: calc(100% - 40px);
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin: 30px auto 20px auto;
    border-radius: 50px;
    overflow: hidden;
    box-sizing: border-box;
    transition: border 0.5s ease;
}

.search-modal-input-icon {
    width: 50px;
    height: 50px;
    padding: 10px;
    box-sizing: border-box;
}

.aa-Autocomplete {
    width: 100% !important;
}

.aa-Form {
    border: none !important;
}

.aa-Form:focus-within {
    outline: none !important;
    box-shadow: none !important;
}

.aa-InputWrapperPrefix {
    display: none !important;
}

.aa-Label, .aa-SubmitButton {
    display: none !important;
}

.aa-Input {
    width: 100% !important;
    height: 50px !important;
    appearance: none !important;
    border: none !important;
    padding: 10px 10px 10px 0 !important;
    box-sizing: border-box !important;
    color: #414042 !important;
    font-family: 'Lato', sans-serif !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    text-align: left !important;
    outline: none !important;
}

.search-modal-input-guide {
    color: #CD5C5C;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 0;
    text-align: center;
    margin: 25px 0;
}

.search-modal-suggestions {
    width: calc(100% - 40px);
    margin: 0 auto;
}

.aa-Panel {
    width: 100% !important;
    max-height: 300px !important;
    margin: 0 auto !important;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    right: unset !important;
    bottom: unset !important;
    overflow: auto !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.184505) !important;
    border-radius: 15px !important;
}

.aa-PanelLayout {
    overflow: unset !important;
    padding: 0 !important;
}

.aa-Item {
    padding: 0 !important;
    display: block !important;
}

.suggestion {
    width: 100%;
    background: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    transition: filter 0.5s ease;
    cursor: pointer;
    padding: 5px 0;
}

.suggestion:hover {
    filter: brightness(95%);
}

.suggestion-icon {
    width: 50px;
    padding: 10px;
    box-sizing: border-box;
}

.suggestion-text {
    width: calc(100% - 50px);
    margin: 0;
    padding: 10px 10px 10px 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
    line-height: 24px;
}

.suggestion-text mark {
    background: none;
    font-weight: 900;
}

.search-modal-actions {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.search-modal-action {
    margin: 10px;
}