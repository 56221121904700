.paginator-page {
    width: 30px;
    height: 30px;
    margin: 5px;
    padding: 8px;
    box-sizing: border-box;
    border: none;
    border-radius: 3px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    transition: background 0.5s ease, color 0.5s ease;
}

.paginator-page-icon {
    width: 100%;
}