.list-item {
    width: 150px;
    min-width: 150px;
    height: 150px;
    min-height: 150px;
    margin: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.179906);
    border-radius: 20px;
    position: relative;
    transition: transform 0.5s ease, border 0.2s ease;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
    background: #FFFFFF;
}

.list-item-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.list-item-image {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100%;
    transition: opacity 0.5s ease;
}

.list-item-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s ease;
}