.subcategory {
    transition: transform 0.5s ease;
    margin-right: 20px;
    width: 200px;
}

.subcategory:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 768px) {
    .subcategory {
        margin: 10px;
    }
    .subcategory:last-child {
        margin-right: 10px;
    }
}

.subcategory-image-wrapper {
    width: 200px;
    height: 200px;
    position: relative;
    border-radius: 100%;
    padding: 40px;
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 0.5s ease;
    text-align: center;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.207878);
    border: 1px solid rgba(0, 0, 0, 0.05);
    overflow: hidden;
    background: #FFFFFF;
}

.subcategory-image-wrapper:hover {
    transform: scale(1.1);
}

.subcategory-image {
    max-width: 100%;
    height: 100%;
    transition: opacity 0.5s ease;
    border-radius: 100%;
}

.subcategory-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s ease;
}

.subcategory-name {
    margin: 10px 0 0 0;
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}