.cross-loader {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: block;
    color: var(--cross-loader-primary-color);
    transition: color 0.5s ease;
}

.cross-loader:before, .cross-loader:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s cross-loader-spin linear infinite;
}

.cross-loader:after {
    color: var(--cross-loader-secondary-color);
    transform: rotateY(70deg);
    animation-delay: .4s;
    transition: color 0.5s ease;
}

@keyframes cross-loader-spin {
    0%,
    100% {
        box-shadow: .2em 0 0 0 currentcolor;
    }
    12% {
        box-shadow: .2em .2em 0 0 currentcolor;
    }
    25% {
        box-shadow: 0 .2em 0 0 currentcolor;
    }
    37% {
        box-shadow: -.2em .2em 0 0 currentcolor;
    }
    50% {
        box-shadow: -.2em 0 0 0 currentcolor;
    }
    62% {
        box-shadow: -.2em -.2em 0 0 currentcolor;
    }
    75% {
        box-shadow: 0 -.2em 0 0 currentcolor;
    }
    87% {
        box-shadow: .2em -.2em 0 0 currentcolor;
    }
}
