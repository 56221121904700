.action-bar {
    width: 80px;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.207878);
    position: absolute;
    z-index: 1000;
    transition: background 0.5s ease;
}

.action-bar-move-action {
    width: 80px;
    height: 90px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    transition: background 0.2s ease;
}

.action-bar-move-wrapper {
    width: 100%;
    transition: background 0.2s ease;
    position: relative;
}

.action-bar-items {
    width: 100%;
}