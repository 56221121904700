.product-loader {
    width: 100%;
    height: 200px;
    display: block;
    margin: auto;
    position: relative;
    background: #FFFFFF;
    box-sizing: border-box;
}

.product-loader::after {
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 15px);
    top: 15px;
    left: 15px;
    position: absolute;
    background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 100%), linear-gradient(#DDD 100px, transparent 0), linear-gradient(#DDD 16px, transparent 0), linear-gradient(#DDD 50px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 175px, 100% 100px, 100% 16px, 100% 30px;
    background-position: -185px 0, center 0, center 115px, center 142px;
    box-sizing: border-box;
    animation: product-loader 1s linear infinite;
}

@keyframes product-loader {
    to {
        background-position: 185px 0, center 0, center 115px, center 142px;
    }
}
