.filter-option {
    padding: 12px 20px;
    box-sizing: border-box;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.207878);
    transition: transform 0.5s ease, background 0.5s ease;
    transform-origin: 50% 50%;
}

.filter-option.animated {
    transform: scale(0);
    animation-name: popup;
    animation-duration: 3s;
    animation-delay: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.filter-option.no-animation {
    transform: scale(1);
    animation-name: unset;
    animation-duration: unset;
    animation-delay: unset;
    animation-iteration-count: unset;
    animation-fill-mode: unset;
}

.filter-option:last-child {
    margin-right: 0;
}

.filter-option-label {
    margin: 0 10px 0 0;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: color 0.5s ease;
}

.filter-option-delete-button {
    width: 26px;
    min-width: 26px;
    height: 26px;
    border-radius: 100%;
    transition: transform 0.5s ease, border 0.5s ease, color 0.5s ease;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
}

.filter-option-delete-button:hover {
    transform: scale(1.2);
}

.filter-option-delete-button-icon {
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
}

@keyframes popup {
    0% {transform: scale(0.3)}
    25% {transform: scale(1.1)}
    50% {transform: scale(0.3)}
    75% {transform: scale(1.1)}
    100% {transform: scale(1)}
}