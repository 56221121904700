.not-found {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
}

.not-found-wrapper {
    width: 80%;
    max-width: 300px;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    overflow: auto;
}

.not-found-client-logo-wrapper {
    width: 100%;
    text-align: center;
}

.not-found-client-logo {
    width: 300px;
    max-width: 100%;
    transition: opacity 0.5s ease;
}

.not-found-title {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: #111111;
}

.not-found-description {
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 300;
    color: #111111;
}

.not-found-action {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}