.button {
    cursor: pointer;
    transition: transform 0.5s ease, filter 0.5s ease, background 0.5s ease, color 0.5s ease, border 0.5s ease;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    display: inline-block;
    position: relative;
}

.button-box-shadow {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-style: solid;
}

.button:hover {
    transform: scale(1.1);
    filter: brightness(95%);
}

.button-loader {
    margin-right: 10px;
}

.button-icon-container {
    margin-right: 10px;
}

.button-notification {
    position: absolute;
    top: -8px;
    right: -8px;
    min-width: 30px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    line-height: 20px;
    transition: background 0.2s ease, color 0.2s ease;
}