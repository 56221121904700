.range-slider {
    position: relative;
    width: 100%;
    height: 40px;
}

.range-slider-input {
    appearance: none;
    width: 100%;
    height: 5px;
    margin: 0;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.range-slider-input::-webkit-slider-thumb {
    appearance: none;
    width: 40px;
    height: 30px;
    border-radius: 40px;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.207878);
    background-color: #FFFFFF;
    background-image: url('images/slider-thumb-icon.png');
    background-position: center center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    pointer-events: all;
}

.range-slider-input.upper {
    background: none;
}

.range-slider-tutorial {
    position: absolute;
    top: 43px;
    background: #FFFFFF;
    border-radius: 10px;
    width: 70px;
    height: 70px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.207878);
}

.range-slider-tutorial-image {
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.range-slider-buttons {
    position: absolute;
    top: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 5px;
    background: #FFFFFF;
    width: 70px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.207878);
    border-radius: 10px;
}