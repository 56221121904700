.banner-external-url-modal {
    width: 100%;
}

@media only screen and (max-width: 768px), (max-height: 600px) {
    .banner-external-url-modal {
        padding: 40px;
        box-sizing: border-box;
    }
}

.banner-external-url-modal-title {
    margin: 0 0 20px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.banner-external-url-modal-description {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.banner-external-url-modal-title .bold, .banner-external-url-modal-description .bold {
    font-weight: 700;
}

.banner-external-url-modal-content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .banner-external-url-modal-content {
        display: block;
        justify-content: unset;
        align-content: unset;
        align-items: unset;
    }
}

.banner-external-url-modal-image {
    display: block;
    margin: 20px;
}

@media only screen and (max-width: 768px) {
    .banner-external-url-modal-image {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin: 40px auto 20px auto;
    }
}

.banner-external-url-modal-qr-image-container {
    width: 200px;
    margin: 20px;
}

@media only screen and (max-width: 768px) {
    .banner-external-url-modal-qr-image-container {
        margin: 20px auto 40px auto;
    }
}

.banner-external-url-modal-qr-image-canvas {
    display: block;
    margin: 0 auto;
}

.banner-external-url-modal-qr-image-description {
    margin: 0;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    text-align: center;
}

.banner-external-url-modal-error {
    width: 450px;
    max-width: 100%;
    margin: 0 auto 50px auto;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    padding: 0 30px;
    box-sizing: border-box;
}

.banner-external-url-modal-actions {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.banner-external-url-modal-action {
    margin: 10px;
}