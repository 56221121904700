.image-viewer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
}

.image-viewer-image {
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    transition: opacity 0.2s ease;
}

.image-viewer-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s ease;
}
