.no-stock-modal {
    width: 100%;
    height: auto;
    padding: 40px;
    box-sizing: border-box;
}

.no-stock-modal-title {
    margin: 0 0 20px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.no-stock-modal-description {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.no-stock-modal-title .bold, .no-stock-modal-description .bold {
    font-weight: 700;
}

.no-stock-modal-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}