.category-title {
    width: calc(100% - 40px);
    height: 100px;
    margin: 0 auto;
    padding: 20px 30px;
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    transition: background 0.5s ease, color 0.5s ease;
}

@media only screen and (max-width: 768px) {
    .category-title {
        width: calc(100% - 40px);
        margin: 0 auto;
    }
}

.category-title-info {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.category-title-text {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 22px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.category-title-icon-container {
    min-width: 70px;
    width: 70px;
    height: 70px;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 100%;
    background: #FFFFFF;
    margin-left: 10px;
}

.category-title-icon {
    width: 100%;
}

.category-title-bottom-border {
    width: 100%;
    height: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: background 0.5s ease;
}