.view {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
}

.view::-webkit-scrollbar {
    width: 5px;
    height: 0;
}

.view::-webkit-scrollbar-track {
    background: #F1F1F1;
}

.view::-webkit-scrollbar-thumb {
    background: #CCCCCC;
}

.view::-webkit-scrollbar-thumb:hover {
    background: #AAAAAA;
}

.view-config-button {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9998;
    width: 80px;
    height: 80px;
    background: #333333;
    border-radius: 100%;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.261172);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: transform 0.5s ease;
    transform-origin: 50% 50%;
}

.view-config-button:hover {
    transform: scale(1.1) translateX(-50%);
}

.view-config-button-icon {
    width: 40px;
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.view-top-spacer {
    width: 100%;
    background: #555555;
    transition: height 0.3s ease;
}

.view-bottom-spacer {
    width: 100%;
    background: #555555;
    transition: height 0.3s ease;
}

.view-action-bar-track {
    width: 80px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background: transparent;
    transition: opacity 0.5s ease;
}

.view-action-bar-track.visible {
    opacity: 1;
}

.view-action-bar-track.hidden {
    opacity: 0;
}

.view-action-bar-mobile-toggle {
    width: 30px;
    height: 50px;
    background: #FFFFFF;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.207878);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: opacity 0.5s ease;
}

.view-action-bar-mobile-toggle.visible {
    opacity: 1;
}

.view-action-bar-mobile-toggle.hidden {
    opacity: 0;
}

.view-action-bar-mobile-icon {
    width: 100%;
    display: block;
}

.view-accessibility-button {
    position: fixed;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
}