.category-option-v2 {
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.category-option-v2:last-child {
    margin-right: 0;
}

.category-image-wrapper-v2 {
    width: 150px;
    height: 150px;
    position: relative;
    border-radius: 100%;
    padding: 15px;
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09019);
    background: #FFFFFF;
    margin: 0 auto;
    overflow: hidden;
}

.category-image-v2 {
    width: 100px;
    height: 100px;
    transition: opacity 0.5s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
}

.category-image-loader-v2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s ease;
}

.category-name-v2 {
    margin: 10px 0 0 0;
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: color 0.2s ease, font-weight 0.2s ease;
}