.model-viewer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.model-viewer-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.model-viewer-iframe {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: none;
    display: block;
    transition: opacity 1s ease;
    overflow: hidden;
}

.model-viewer-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
    transition: opacity 1s ease;
    overflow: hidden;
}

.model-viewer-error {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
    transition: opacity 1s ease;
    overflow: hidden;
}