.slider {
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.slider-wrapper {
    width: calc(100% - 80px);
}

.slider-input {
    appearance: none;
    width: 100%;
    height: 5px;
    margin: 0;
    border-radius: 20px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.slider-input::-webkit-slider-thumb {
    appearance: none;
    width: 40px;
    height: 30px;
    border-radius: 40px;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.207878);
    background-color: #FFFFFF;
    background-image: url('images/slider-thumb-icon.png');
    background-position: center center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    pointer-events: all;
}