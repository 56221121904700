.payment-step-actions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.payment-step-action {
    margin: 10px;
}