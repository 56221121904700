.variant {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: border 0.5s ease, filter 0.5s ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: #FFFFFF;
}

.variant:hover {
    filter: brightness(0.85);
}

.variant-image-container {
    width: auto;
}

.variant-name {
    width: 100%;
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #414042;
}

.variant-box-info-prices {
    width: 100%;
    margin-top: 20px;
}

.variant-box-info-price {
    width: 100%;
    text-align: left;
    letter-spacing: 0;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.variant-box-info-price.exclusive {
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-weight: 700;
    transition: color 0.5s ease;
}

.variant-box-info-price.offer {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-weight: 700;
}

.variant-box-info-price.normal {
    color: #757575;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 300;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.variant-box-info-normal-price-wrapper {
    text-decoration: line-through;
    margin-left: 5px;
}

.variant-box-info-card {
    margin-left: 10px;
}

.variant-box-info-discount {
    margin-left: 10px;
    border-radius: 25px;
    padding: 3px 12px;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    transition: background 0.5s ease, color 0.5s ease;
}