.header {
    width: 100%;
    background: transparent;
    position: relative;
}

.header-client-logo {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    transition: background 0.5s ease;
}

.header-category {
    width: 100%;
}

.header-category-info-wrapper {
    width: 100%;
    height: 100px;
    padding: 20px 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    transition: background 0.5s ease;
}

.header-category-info-container {
    width: 100%;
}

.header-category-name {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 25px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.5s ease;
    text-align: right;
}

.header-products-quantity {
    margin-top: 5px;
    color: #B2B2B2;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 0;
    text-align: right;
}

.header-category-image {
    position: absolute;
    top: 50%;
    left: 20px;
    height: 150px;
    transition: transform 0.5s ease;
    transform-origin: 50% 0;
    border-radius: 15px;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.207878);
    padding: 10px;
    box-sizing: border-box;
    background: #FFFFFF;
}

@media only screen and (max-width: 768px) {
    .header-category-image {
        display: none;
    }
}