.waiting-payment {
    height: auto;
    padding: 30px;
    box-sizing: border-box;
}

.waiting-payment-title {
    margin: 0 0 20px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    box-sizing: border-box;
}

@media only screen and (max-width: 1000px), (max-height: 1000px) {
    .waiting-payment-title {
        padding: 40px;
        margin: 0;
        box-sizing: border-box;
    }
}

.waiting-payment-title .bold {
    font-weight: 700;
}

.waiting-payment-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}