.payment-option {
    max-width: calc(100% - 40px);
    min-height: 380px;
    margin: 20px;
    padding: 30px 40px;
    box-sizing: border-box;
    border-radius: 20px;
    cursor: pointer;
    transition: transform 0.5s ease;
    position: relative;
    overflow: hidden;
}

.payment-option.hover:hover {
    transform: scale(1.05);
}

.payment-option.shadow {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.261172);
}

@media only screen and (max-width: 768px) {
    .payment-option {
        margin: 20px auto;
    }
}

.payment-option-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    transition: filter 0.5s ease;
}

.payment-option-wrapper.disabled {
    filter: grayscale(100%) invert(100%);
}

.payment-option-icon {
    margin: 0 auto 60px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 250px;
    min-height: 220px;
}

.payment-option-label {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.payment-option-label-item {
    display: inline;
}

.payment-option-unavailable {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    width: 80%;
    height: auto;
    background: #CD5C5C;
    color: #FFFFFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    padding: 15px 20px;
    box-sizing: border-box;
    border-radius: 5px;
}

.payment-option-timer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.payment-option-timer-icon {
    width: 80px;
    animation: hourglass 2s linear infinite;
}

.payment-option-timer-label {
    color: #FFFFFF;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    padding: 15px;
    box-sizing: border-box;
    min-width: 100%;
}

@keyframes hourglass {
    0% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(180deg);
    }
    60% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}