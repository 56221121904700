.inactivity-modal {
    width: 100%;
}

@media only screen and (max-width: 768px), (max-height: 600px) {
    .inactivity-modal {
        padding: 40px;
        box-sizing: border-box;
    }
}

.inactivity-modal-title {
    margin: 0 0 20px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 900;
    letter-spacing: 0;
    text-align: center;
}

.inactivity-modal-description {
    margin: 0 0 20px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.inactivity-modal-timer {
    margin: 40px 0;
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    font-weight: 900;
    letter-spacing: 0;
    text-align: center;
    transition: color 0.5s ease;
}

.inactivity-modal-actions {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

@media only screen and (max-width: 768px), (max-height: 600px) {
    .inactivity-modal-actions {
        display: block;
        justify-content: unset;
        align-content: unset;
        align-items: unset;
    }
}

.inactivity-modal-action {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}