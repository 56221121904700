.action-bar-mobile-item {
    margin: 20px 0;
    padding: 20px 30px;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    transition: background 0.2s ease;
}

.action-bar-mobile-item:first-child {
    margin-top: 0;
}

.action-bar-mobile-item-icon {
    display: block;
    margin-right: 20px;
    position: relative;
}

.action-bar-mobile-item-normal-state {
    width: auto;
}

.action-bar-mobile-item-active-state {
    position: absolute;
    top: 0;
    left: 0;
}

.action-bar-mobile-item-disabled-state {
    position: absolute;
    top: 0;
    left: 0;
}

.action-bar-mobile-item-label {
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-align: center;
    font-size: 18px;
    transition: color 0.2s ease;
}

.action-bar-mobile-item-number {
    position: absolute;
    top: 15px;
    left: 15px;
    min-width: 30px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    line-height: 20px;
    transition: background 0.2s ease, color 0.2s ease;
}