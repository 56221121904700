.payment-modal {
    width: 100%;
}

.payment-modal-loader, .payment-modal-error {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}