.payment-identification {
    padding: 40px;
    box-sizing: border-box;
}

.payment-identification-title {
    margin: 0 0 5px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.payment-identification-description {
    margin: 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
}

.payment-identification-input-container {
    margin: 70px auto;
    width:  600px;
    max-width: 100%;
}

.payment-identification-input-error {
    color: #CD5C5C;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 0;
    text-align: center;
}