.shopping-cart {
    width: 100%;
    height: 100%;
}

.shopping-cart-loader, .shopping-cart-error {
    width: 100%;
}

.shopping-cart-loader-wrapper, .shopping-cart-error-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.shopping-cart-items {
    width: 100%;
    height: 50%;
    background: #FFFFFF;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.shopping-cart-items::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.shopping-cart-totals {
    width: 100%;
    height: 50%;
    box-shadow: 0 0 10px rgba(0,0,0, 0.216722);
    overflow: auto;
}

.shopping-cart-total {
    width: 100%;
    margin: 20px 0;
    padding: 0 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.shopping-cart-total-label {
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    transition: color 0.5s ease;
}

.shopping-cart-total-value {
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: right;
    transition: color 0.5s ease;
}

.shopping-cart-total-error {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    padding: 20px 40px;
    box-sizing: border-box;
}

.shopping-cart-total-error-icon {
    width: 30px;
    margin-right: 20px;
}

.shopping-cart-total-error-label {
    font-size: 20px;
    color: #CD5C5C;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    letter-spacing: 0;
    transition: color 0.5s ease;
}

.shopping-cart-actions {
    width: 100%;
    overflow: auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.shopping-cart-action {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.shopping-cart-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.shopping-cart-empty-icon-container {
    width: 80px;
    height: 80px;
    margin: 0 auto 20px auto;
    background: #DFDFDF;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 100%;
    position: relative;
}

.shopping-cart-empty-icon {
    width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.shopping-cart-empty-title {
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0;
    margin: 0;
}

.shopping-cart-empty-description {
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    margin: 20px 0;
}

.shopping-cart-empty-action {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 20px;
}