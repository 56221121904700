.banner-carousel-container {
    width: 100%;
    position: relative;
    z-index: 0;
}

.banner-carousel {
    width: 100%;
    overflow: hidden;
}

.banner-carousel-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.banner-container {
    min-width: 100%;
    cursor: pointer;
    background: #EDEDED;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.banner-carousel-dots {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    overflow: auto;
    padding: 10px 0;
    box-sizing: border-box;
}

.banner-carousel-dots::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.banner-carousel-dots::-webkit-scrollbar-track {
    background: #F1F1F1;
}

.banner-carousel-dots::-webkit-scrollbar-thumb {
    background: #CCCCCC;
}

.banner-carousel-dots::-webkit-scrollbar-thumb:hover {
    background: #AAAAAA;
}

.banner-carousel-dots-wrapper {
    display: inline-flex;
}