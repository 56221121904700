.product-variants-sidebar {
    position: absolute;
    top: 0;
    z-index: 1001;
    width: 320px;
    height: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.22);
    transition: right 0.5s ease, opacity 0.2s ease;
}

.product-variants-sidebar-tabs-container {
    width: 100%;
    box-sizing: border-box;
}

.product-variants-sidebar-content {
    padding: 0 20px;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    flex-wrap: wrap;
}

.product-variants-sidebar-content::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.product-variants-sidebar-content::-webkit-scrollbar-track {
    display: none;
}

.product-variants-sidebar-content::-webkit-scrollbar-thumb {
    display: none;
}

.product-variants-sidebar-content::-webkit-scrollbar-thumb:hover {
    display: none;
}

.product-variants-sidebar-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    border-top: solid 1px rgba(0, 0, 0, 0.05);
}

.product-variants-sidebar-action {
    margin: 10px;
}

.product-variants-sidebar-error {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.product-variants-sidebar-error-message {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0;
}