.integrated-pos-container {
    width: 100%;
    height: auto;
}

.integrated-pos-loader, .integrated-pos-error {
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

#payment-document-iframe, #budget-print-iframe {
    width: 0;
    height: 0;
    visibility: hidden;
    border: none;
    position: fixed;
    top: 0;
    left: 0;
}

.integrated-pos-no-stock-modal {
    width: 100%;
    height: auto;
}

.integrated-pos-no-stock-modal-title {
    margin: 0 0 20px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.integrated-pos-no-stock-modal-description {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.integrated-pos-no-stock-modal-title .bold, .integrated-pos-no-stock-modal-description .bold {
    font-weight: 700;
}

.integrated-pos-no-stock-modal-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}