.section-title-container {
    width: 100%;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
}

.section-title {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 900;
    font-style: normal;
    letter-spacing: 0;
}

.section-subtitle {
    margin-top: 5px;
    color: #B2B2B2;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 0;
}