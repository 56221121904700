.normal-filter {
    margin: 10px;
    background: #FFFFFF;
    padding: 10px;
    box-sizing: border-box;
    min-width: 150px;
    max-width: 250px;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.5s ease, filter 0.5s ease;
    transform-origin: 50% 50%;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.207878);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.normal-filter:hover {
    transform: scale(1.1);
    filter: brightness(95%);
}

.normal-filter-check-container {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background: #FFFFFF;
    border-radius: 5px;
    transition: background 0.5s ease, border 0.5s ease;
}

.normal-filter-check {
    width: 100%;
    height: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.normal-filter-label {
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #111111;
    max-width: calc(100% - 40px);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}