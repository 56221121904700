.payment-message {
    height: auto;
    padding: 30px;
    box-sizing: border-box;
}

.payment-message-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.payment-message-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 10px;
}


.payment-message-wrapper { /* Comment this class style to show app-generated documents previews */
    width: 0;
    height: 0;
    overflow: hidden;
}

.payment-message-preview {
    border: solid 3px #000000;
    max-height: 500px;
    overflow: auto;
    zoom: 0.75;
}

.payment-message-preview::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.payment-message-preview::-webkit-scrollbar-track {
    background: #F1F1F1;
}

.payment-message-preview::-webkit-scrollbar-thumb {
    background: #CCCCCC;
}

.payment-message-preview::-webkit-scrollbar-thumb:hover {
    background: #AAAAAA;
}

.payment-message-submit-message {
    margin: 20px 0;
    width: 100%;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 22px;
}

.payment-message-submit-message.success {
    color: #111111;
}

.payment-message-submit-message.error {
    color: #CD5C5C;
}