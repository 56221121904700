/* FULL */

.product-box {
    width: 280px;
    min-width: 280px;
    background: #FFFFFF;
    margin: 10px;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.207878);
    border-radius: 15px;
    cursor: pointer;
    transition: filter 0.5s ease, transform 0.5s ease;
    box-sizing: border-box;
    position: relative;
}

.product-box:hover {
    filter: brightness(95%);
}

.product-box-pulse-cover {
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 15px;
    overflow: hidden;
}

.product-box-active-cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    transition: opacity 0.5s ease;
    border-radius: 15px;
}

.product-box-top-bar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 60px;
    padding: 10px 8px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 40px;
    transition: background 0.5s ease, color 0.5s ease;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.product-box-top-bar.active {
    z-index: 2;
    background: #666666;
    color: #FFFFFF;
}

.product-box-top-bar.offer {
    z-index: 1;
}

.product-box-3d {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
}

.product-box-featured {
    position: absolute;
    top: 13px;
    right: 10px;
    z-index: 1;
}

.product-box-wrapper {
    width: 100%;
    padding: 75px 15px 15px 15px;
    box-sizing: border-box;
}

.product-box-image-container {
    position: relative;
    width: 100%;
    text-align: center;
}

.product-box-external-sale-tag-container {
    position: absolute;
    bottom: 10px;
    left: 0;
}

.product-box-image {
    max-width: 100%;
    height: 200px;
}

.product-box-info {
    margin-top: 10px;
}

.product-box-info-sku {
    width: 100%;
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: normal;
    color: lightgray;
}

.product-box-info-brand {
    width: 100%;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #414042;
    margin-top: 15px;
}

.product-box-info-name {
    width: 100%;
    height: 45px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin-top: 3px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #414042;
}

.product-box-info-seller-container {
    width: 100%;
    height: 60px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.product-box-info-seller-icon-container {
    margin-right: 6px;
}

.product-box-info-seller-message {
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #414042;
    width: 100%;
}

.product-box-info-seller-message.two-lines {
    -webkit-line-clamp: 2;
}

.product-box-info-seller-message.three-lines {
    -webkit-line-clamp: 3;
}

.product-box-info-seller {
    font-weight: bold;
    font-size: 16px;
    transition: color 0.5s ease;
}

.product-box-info-main-seller-icon-container {
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0;
}

.product-box-info-delivery-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.product-box-info-delivery-icon-container {
    margin-right: 6px;
}

.product-box-info-delivery {
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #414042;
    width: 100%;
}

.product-box-info-prices {
    width: 100%;
    height: 85px;
    margin-top: 20px;
}

.product-box-info-price {
    width: 100%;
    text-align: left;
    letter-spacing: 0;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.product-box-info-price.exclusive {
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 700;
    transition: color 0.5s ease;
}

.product-box-info-price.offer {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 700;
}

.product-box-info-price.normal {
    color: #757575;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 300;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.product-box-info-normal-price-wrapper {
    text-decoration: line-through;
    margin-left: 5px;
}

.product-box-info-card {
    margin-left: 10px;
}

.product-box-info-discount {
    margin-left: 10px;
    border-radius: 25px;
    padding: 3px 12px;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    transition: background 0.5s ease, color 0.5s ease;
}

.product-box-info-options {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.product-box-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    transition: opacity 0.5s ease;
}

/* SIMPLE */

.product-box-wrapper.simple {
    width: 100%;
    padding: 0;
    box-sizing: border-box;
}

.product-box-image-container.simple {
    display: flex;
    width: 100%;
}

.product-box-image.simple {
    width: 100%;
    aspect-ratio: 1 / 1;
    height: auto;
    object-fit: contain;
}

.product-box-info.simple {
    margin: 0;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
}