.filters-options {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.filters-options::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.filters-options::-webkit-scrollbar-track {
    background: #F1F1F1;
}

.filters-options::-webkit-scrollbar-thumb {
    background: #CCCCCC;
}

.filters-options::-webkit-scrollbar-thumb:hover {
    background: #AAAAAA;
}