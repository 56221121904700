.payment-option-selection {
    height: auto;
    box-sizing: border-box;
}

.payment-option-selection-title {
    margin: 0 0 20px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    box-sizing: border-box;
}

@media only screen and (max-width: 1000px), (max-height: 1000px) {
    .payment-option-selection-title {
        padding: 40px;
        margin: 0;
        box-sizing: border-box;
    }
}

.payment-option-selection-title .bold {
    font-weight: 700;
}

.payment-option-selection-content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    position: relative;
}

.payment-option-selection-content::-webkit-scrollbar {
    width: 0;
    height: 0;
}

@media only screen and (max-width: 1000px), (max-height: 1000px) {
    .payment-option-selection-content {
        padding: 0;
    }
}

@media only screen and (max-width: 768px) {
    .payment-option-selection-content {
        display: block;
        justify-content: unset;
        align-content: unset;
        align-items: unset;
    }
}