.accessibility-button {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 100%;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.207878);
    transition: transform 0.5s ease, background 0.5s ease;
    cursor: pointer;
}

.accessibility-button-icon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}