.product-view {
    width: 100%;
    height: auto;
    min-height: 100%;
    box-sizing: border-box;
    position: relative;
}

.product-view-loader, .product-view-error {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
}

.product-view-loader-wrapper, .product-view-error-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.product-view-content {
    transition: width 0.5s ease;
}

.product-view-header-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.product-view-footer-wrapper {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.product-view-product-title {
    width: 100%;
    transition: opacity 0.5s ease;
}

.product-view-product-title.visible {
    opacity: 1;
}

.product-view-product-title.hidden {
    opacity: 0;
}

.product-view-product-list {
    width: 100%;
}

.product-view-product-tabs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.product-view-images-mode-tab {
    background: white;
    padding: 8px 15px;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.179906);
    cursor: pointer;
    margin: 5px;
    border-radius: 15px;
    transition: transform 0.5s ease;
}

.product-view-images-mode-tab.visible {
    transform: scale(1);
}

.product-view-images-mode-tab.hidden {
    transform: scale(0);
}

.product-view-images-mode-tab.selected {
    border: solid 5px #CCCCCC;
}

.product-view-viewer {
    height: 100%;
    position: absolute;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: width 0.5s ease;
}

.product-view-icon-buttons-grid-toggle {
    width: 30px;
    height: 50px;
    background: #FFFFFF;
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 1000;
    transform: translateY(-50%);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.207878);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.product-view-icon-buttons-grid-toggle-icon {
    width: 100%;
    display: block;
    transform: rotate(180deg);
}

.product-view-icon-buttons-grid.normal {
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 2;
    transform: translateY(-50%);
}

.product-view-icon-buttons-grid.mobile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    padding: 70px 0 0 0;
}

#looking-similar {
    display: none;
}