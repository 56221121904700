.modal-blocking-panel {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity 0.2s ease;
}

.modal-container {
    position: absolute;
    box-shadow: 0 2px 64px rgba(94, 73, 28, 0.410382);
    box-sizing: border-box;
    transition: background 0.5s ease;
}

.modal-container.top {
    width: 100%;
    top: 0;
    left: 0;
}

.modal-container.right {
    height: 100%;
    top: 0;
    right: 0;
}

.modal-container.bottom {
    width: 100%;
    bottom: 0;
    left: 0;
}

.modal-container.left {
    height: 100%;
    top: 0;
    left: 0;
}

.modal-container.center {
    top: 50%;
    left: 50%;
    transform-origin: 0;
}

.modal-container.semi-center {
    top: 38%;
    left: 50%;
    transform-origin: 0;
}

.modal-close-button {
    position: absolute;
    z-index: 1;
    background: var(--modal-close-button-background-color);
    border-radius: 100%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    box-shadow: 1px 2px 14px rgba(0, 0, 0, 1);
    transition: transform 0.5s ease, filter 0.5s ease, background 0.5s ease;
}

.modal-close-button.disabled {
    background: #999999;
}

.modal-close-button-icon {
    display: block;
    position: absolute;
    top: 9px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    font-family: 'Roboto', sans-serif;
    color: var(--modal-close-button-label-color);
    transition: color 0.5s ease;
}

.modal-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.modal-content::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.modal-content::-webkit-scrollbar-track {
    display: none;
}

.modal-content::-webkit-scrollbar-thumb {
    display: none;
}

.modal-content::-webkit-scrollbar-thumb:hover {
    display: none;
}