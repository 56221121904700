.payment-iframe {
    width: 100%;
    height: auto;
}

.payment-iframe-title {
    margin: 0 0 20px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    box-sizing: border-box;
}

@media only screen and (max-width: 1000px), (max-height: 1000px) {
    .payment-iframe-title {
        padding: 40px;
        margin: 0;
        box-sizing: border-box;
    }
}

.payment-iframe-content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    position: relative;
}

.payment-iframe-content::-webkit-scrollbar {
    width: 0;
    height: 0;
}

@media only screen and (max-width: 1000px), (max-height: 1000px) {
    .payment-iframe-content {
        padding: 0;
    }
}

@media only screen and (max-width: 768px) {
    .payment-iframe-content {
        display: block;
        justify-content: unset;
        align-content: unset;
        align-items: unset;
    }
}

.payment-iframe-module {
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease;
    position: relative;
    z-index: 1;
    border: none;
}

.payment-iframe-module-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s ease;
    overflow: hidden;
}

.payment-iframe-module-error {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s ease;
    overflow: hidden;
}

.payment-iframe-error {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.payment-iframe-actions-container {
    box-sizing: border-box;
}