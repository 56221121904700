.categories-view {
    width: 100%;
    height: auto;
    min-height: 100%;
    box-sizing: border-box;
    position: relative;
}

.categories-view-loader, .categories-view-error {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
}

.categories-view-loader-wrapper, .categories-view-error-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.categories-view-content-wrapper {
    overflow: auto;
    box-sizing: border-box;
    transition: padding 0.5s ease;
}

.categories-view-list {
    width: 100%;
}

.categories-view-no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}