.payment-qr-scan {
    height: auto;
    padding: 30px;
    box-sizing: border-box;
}

.payment-qr-scan-title {
    margin: 0 0 20px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    box-sizing: border-box;
}

@media only screen and (max-width: 1000px), (max-height: 1000px) {
    .payment-qr-scan-title {
        padding: 40px;
        margin: 0;
        box-sizing: border-box;
    }
}

.payment-qr-scan-description {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.payment-qr-scan-title .bold, .payment-qr-scan-description .bold {
    font-weight: 700;
}

.payment-qr-scan-content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    position: relative;
}

.payment-qr-scan-content::-webkit-scrollbar {
    width: 0;
    height: 0;
}

@media only screen and (max-width: 1000px), (max-height: 1000px) {
    .payment-qr-scan-content {
        padding: 0;
    }
}

@media only screen and (max-width: 768px) {
    .payment-qr-scan-content {
        display: block;
        justify-content: unset;
        align-content: unset;
        align-items: unset;
    }
}

.payment-qr-scan-image {
    width: 250px;
    min-height: 250px;
    display: block;
    margin: 20px;
    border-radius: 100%;
}

@media only screen and (max-width: 768px) {
    .payment-qr-scan-image {
        margin: 20px auto;
    }
}

.payment-qr-scan-qr-image-container {
    width: 250px;
    margin: 20px;
}

@media only screen and (max-width: 768px) {
    .payment-qr-scan-qr-image-container {
        margin: 20px auto;
    }
}

.payment-qr-scan-qr-image-canvas {
    display: block;
    margin: 0 auto;
}

.payment-qr-scan-qr-image-description {
    margin: 0;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    text-align: center;
}

.payment-qr-scan-error {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}