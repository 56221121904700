.survey-modal {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    overflow: hidden;
}

.survey-modal-loader, .survey-modal-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.survey-modal-iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.survey-modal-error {
    width: 450px;
    max-width: 100%;
    margin: 0 auto 50px auto;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    padding: 0 30px;
    box-sizing: border-box;
}

.survey-modal-actions {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.survey-modal-action {
    margin: 10px;
}