.category-view {
    width: 100%;
    height: auto;
    min-height: 100%;
    box-sizing: border-box;
    position: relative;
}

.category-view-loader, .category-view-error {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
}

.category-view-loader-wrapper, .category-view-error-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.category-view-header-container {
    width: 100%;
}

.category-view-content-wrapper {
    overflow: auto;
    box-sizing: border-box;
    transition: padding 0.5s ease;
}

.category-view-section-title-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
}

.category-view-section-title-wrapper-sticky-bar-image {
    width: auto;
}

.category-view-section-title-container {
    width: auto;
}

.category-view-sticky-bar {
    width: 100%;
    height: auto;
    transition: top 0.2s ease, padding 0.2s ease;
    box-sizing: border-box;
}

.category-view-sticky-bar.enabled {
    position: fixed;
    padding: 30px 30px 30px 90px;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.22);
    background: #FFFFFF;
}

@media only screen and (max-width: 800px) {
    .category-view-sticky-bar.enabled {
        padding: 100px 30px 30px 30px;
    }
}

.category-view-categories-options-container {
    width: 100%;
}

.category-view-actions-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.category-view-filter-button-container {
    width: 200px;
    max-width: 100%;
    box-sizing: border-box;
}

.category-view-sort-select-container {
    width: 320px;
    box-sizing: border-box;
}

.category-view-filters-options-container {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .category-view-actions-container {
        display: block;
        justify-content: unset;
        align-items: unset;
        align-content: unset;
    }
    .category-view-filter-button-container {
        width: 360px;
        padding: 10px;
        margin: 0 auto;
    }
    .category-view-sort-select-container {
        width: 100%;
        max-width: 360px;
        padding: 10px;
        margin: 0 auto;
    }
    .category-view-filters-options-container {
        width: 100%;
    }
}

.category-view-products-list-container {
    width: 100%;
}

.category-view-filter-results {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    color: #B2B2B2;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    font-style: italic;
    text-align: center;
    letter-spacing: 0;
}

.category-view-products-list-paginator {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.category-view-products-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.category-view-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 100px;
}