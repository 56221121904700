.action-bar-item {
    width: 80px;
    height: 90px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    transition: background 0.2s ease;
}

.action-bar-item-wrapper {
    width: 100%;
    transition: background 0.2s ease;
}

.action-bar-item-state {
    width: 100%;
    height: 90px;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
}

.action-bar-item-state-wrapper {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.action-bar-item-normal-state {
    width: 100%;
}

.action-bar-item-active-state {
    position: absolute;
    top: 0;
    left: 0;
}

.action-bar-item-disabled-state {
    position: absolute;
    top: 0;
    left: 0;
}

.action-bar-item-icon {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.action-bar-item-label {
    margin: 7px 0 0 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 15px;
    transition: color 0.2s ease;
}

.action-bar-item-number {
    position: absolute;
    top: 5px;
    right: 5px;
    min-width: 30px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    line-height: 20px;
    transition: background 0.2s ease, color 0.2s ease;
}