.custom-select-option {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
    padding: 12px 20px;
    box-sizing: border-box;
    transition: background 0.5s ease;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.custom-select-option.custom {
    padding: 3px 12px;
}