.custom-select {
    width: 100%;
    position: relative;
    z-index: 1;
}

.custom-select-label {
    display: block;
    color: #111111;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 10px;
}

.custom-select-selection {
    height: 54px;
    border-radius: 12px;
    background-color: #FFFFFF;
    padding: 10px 20px;
    box-sizing: border-box;
    cursor: pointer;
    transition: border 0.5s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.custom-select-selection-value-label {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
    margin: 0;
}

.custom-select-selection-custom-container {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100%;
}

.custom-select-selection-label-value-prefix-selection {
    font-weight: 700;
    transition: color 0.5s ease;
}

.custom-select-selection-icon-container {
    width: 25px;
    height: 25px;
    position: relative;
}

.custom-select-selection-icon {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom-select-options {
    border-radius: 12px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.189332);
    background-color: #FFFFFF;
    width: 100%;
    max-height: 220px;
    position: absolute;
    left: 0;
    z-index: 1002;
}

.custom-select-options-search {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    border-bottom: solid 1px #EEEEEE;
}

.custom-select-options-search-input-wrapper {
    width: 100%;
    position: relative;
}

.custom-select-options-search-input {
    width: 100%;
    padding: 8px 8px 8px 36px;
    box-sizing: border-box;
    border-radius: 3px;
    border: solid 2px #CCCCCC;
    outline: none;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
    color: #414042;
}

.custom-select-options-search-icon {
    width: 36px;
    padding: 5px 5px 5px 5px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
}

.custom-select-options-wrapper {
    height: auto;
    overflow: auto;
}