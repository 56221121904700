.spinner-loader {
    display: block;
    position: relative;
}

.spinner-loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 0;
    border-width: 4px;
    border-style: solid;
    border-radius: 100%;
    animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.spinner-loader div:nth-child(1) {
    animation-delay: -0.45s;
}

.spinner-loader div:nth-child(2) {
    animation-delay: -0.3s;
}

.spinner-loader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}