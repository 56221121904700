.data-error {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
}

.data-error-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.data-error-message {
    width: 100%;
    margin-top: 20px;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
}

.data-error-actions {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.data-error-action {
    margin: 10px;
}