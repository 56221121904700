.payment-register {
    height: auto;
    padding: 30px;
    box-sizing: border-box;
}

.payment-register-title {
    margin: 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    box-sizing: border-box;
}

@media only screen and (max-width: 1000px), (max-height: 1000px) {
    .payment-register-title {
        padding: 40px 40px 0 40px;
        margin: 0;
        box-sizing: border-box;
    }
}

.payment-register-title .bold {
    font-weight: 700;
}

.payment-register-subtitle {
    width: 100%;
    text-align: center;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    margin: 5px 0 30px 0;
}

.payment-register-content {
    width: 100%;
}

.payment-input-row {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: flex-start;
}

@media only screen and (max-width: 1000px), (max-height: 1000px) {
    .payment-input-row {
        display: block;
        justify-content: unset;
        align-content: unset;
        align-items: unset;
    }
}

.payment-input-container {
    width: 100%;
    margin: 10px;
}

@media only screen and (max-width: 1000px), (max-height: 1000px) {
    .payment-input-container {
        margin: 10px 0;
    }
}

.payment-register-actions-container {
    margin-top: 20px;
}

.payment-register-errors {
    width: 100%;
    margin-top: 10px;
}

.payment-register-error {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #D88181;
}

.payment-register-save-error {
    margin: 25px auto 0 auto;
    width: 75%;
    text-align: center;
    background: #D88181;
    padding: 8px 5px;
    border-radius: 12px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
}