.update-alert {
    position: relative;
    width: 100%;
    height: auto;
    background: #333333;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.update-alert-label {
    color: white;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 25px;
}

.update-alert-cancel-button {
    margin: 10px;
}

.update-alert-progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: lightgrey;
}

.update-alert-progress-bar-fill {
    height: 100%;
    background: #CD5C5C;
    transition: width 1s linear;
}