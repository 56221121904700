.data-loader {
    width: 100%;
    max-width: 300px;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.data-loader-spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.data-loader-message {
    width: 100%;
    margin-top: 20px;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
}