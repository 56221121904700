.banner-carousel-dot {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 100%;
    background: #FFFFFF;
    margin: 5px;
    cursor: pointer;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.207878);
    transition: background 0.5s ease;
}