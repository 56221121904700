.quick-filter-option {
    padding: 12px 20px;
    box-sizing: border-box;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.207878);
    transition: transform 0.5s ease, background 0.5s ease, filter 0.5s ease;
    transform-origin: 50% 50%;
    cursor: pointer;
    transform: scale(0);
    animation-name: popup;
    animation-duration: 3s;
    animation-delay: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.quick-filter-option:hover {
    transform: scale(1.1);
    filter: brightness(95%);
}

.quick-filter-option:last-child {
    margin-right: 0;
}

.quick-filter-option-label {
    margin: 0 10px 0 0;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: color 0.5s ease;
}

.quick-filter-check-container {
    width: 30px;
    min-width: 30px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 5px;
    transition: background 0.5s ease, border 0.5s ease;
}

.quick-filter-check {
    width: 100%;
    height: 100%;
    padding: 5px;
    box-sizing: border-box;
}

@keyframes popup {
    0% {transform: scale(0.3)}
    25% {transform: scale(1.1)}
    50% {transform: scale(0.3)}
    75% {transform: scale(1.1)}
    100% {transform: scale(1)}
}