.price-filter {
    width: calc(100% - 50px);
    border-radius: 25px;
    box-shadow: 0 0 6px rgba(0,0,0, 0.070615);
    background: #FFFFFF;
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto 20px auto;
}

.price-filter:last-child {
    margin-bottom: 35px;
}

.price-filter-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.price-filter-label {
    color: #333333;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: left;
    margin: 0;
}

.price-filter-value {
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-weight: 900;
    letter-spacing: 0;
    text-align: right;
    color: #333333;
    margin: 0;
}

.price-filter-slider {
    margin-top: 20px;
}