.custom-input {
    width: 100%;
}

.custom-input-label {
    display: block;
    color: #111111;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 10px;
}

.custom-input-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: #FFFFFF;
    transition: border 0.5s ease;
}

.custom-input-inset-label-container {
    width: auto;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 5px 10px;
    box-sizing: border-box;
}

.custom-input-inset-label {
    color: #B2B2B2;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
}

.custom-input-value {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: border 0.5s ease;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
    margin: 0;
    outline: none;
}

.custom-input-clear-button-container {
    width: auto;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 5px 10px;
    box-sizing: border-box;
}

.custom-input-clear-button {
    width: 35px;
    height: 35px;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    transition: transform 0.5s ease, filter 0.5s ease, background 0.5s ease, color 0.5s ease;
}

.custom-input-clear-button:hover {
    filter: brightness(85%);
    transform: scale(1.1);
}

.custom-input-clear-button-icon {
    width: 0;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 25px;
}

/* GOOGLE PLACES */

.pac-container {
    z-index: 1001 !important;
}