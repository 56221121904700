.link-button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
}

.link-button-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.link-button-label {
    color: #7A797A;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    text-decoration: underline;
}