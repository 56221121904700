.filter-type {
    width: 120px;
    min-width: 120px;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 25px;
    margin: 10px;
    cursor: pointer;
    transition: transform 0.5s ease, filter 0.5s ease, background 0.5s ease;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.207878);
    text-align: center;
}

.filter-type:hover {
    transform: scale(1.1);
    filter: brightness(95%);
}

.filter-type-icon-wrapper {
    position: relative;
}

.filter-type-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.filter-type-icon-normal {
    width: auto;
}

.filter-type-icon-active {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.filter-type-label {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    transition: color 0.2s ease;
}
