.shopping-cart-item {
    width: calc(100% - 40px);
    padding: 0;
    margin: 10px auto;
    border-radius: 20px;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.207878);
    cursor: pointer;
}

.shopping-cart-item:last-child {
    margin: 20px auto 10px auto;
}

.shopping-cart-item:first-child {
    margin: 10px auto 20px auto;
}

.shopping-cart-item-data {
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    align-content: center;
}

.shopping-cart-item-image-wrapper {
    position: relative;
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    margin-right: 10px;
    border: solid 1px #eeeeee;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 20px;
}

@media only screen and (max-width: 1100px) {
    .shopping-cart-item-image-wrapper {
        display: none;
    }
}

.shopping-cart-item-image {
    width: 100%;
    transition: opacity 0.5s ease;
    display: block;
    border-radius: 13px;
}

.shopping-cart-item-image-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s ease;
}

.shopping-cart-item-details {
    margin-right: 10px;
}

.shopping-cart-item-name {
    color: #333333;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.shopping-cart-item-prices {
    width: 100%;
    margin-top: 10px;
}

.shopping-cart-item-price {
    width: 100%;
    text-align: left;
    letter-spacing: 0;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.shopping-cart-item-price.exclusive {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 700;
    transition: color 0.5s ease;
}

.shopping-cart-item-price.offer {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin-top: 2px;
}

.shopping-cart-item-price.normal {
    color: #757575;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-top: 2px;
}

.shopping-cart-item-normal-price-wrapper {
    text-decoration: line-through;
    margin-left: 5px;
}

.shopping-cart-item-card {
    margin-left: 10px;
}

.shopping-cart-item-discount {
    margin-left: 10px;
    border-radius: 25px;
    padding: 3px 12px;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    transition: background 0.5s ease, color 0.5s ease;
}

.shopping-cart-final-price {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0;
}

.shopping-cart-final-price.normal {
    margin-top: 0;
    text-align: right;
}

.shopping-cart-final-price.small {
    margin: 20px 0;
    padding: 0 20px;
    text-align: left;
}

.shopping-cart-item-actions {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.shopping-cart-item-delete-container {
    margin: 0 8px;
}

.shopping-cart-item-quantity-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    cursor: pointer;
}

.shopping-cart-item-quantity-increase {
    position: relative;
    border-top: solid 1px #DFDFDF;
    border-bottom: solid 1px #DFDFDF;
    border-right: solid 1px #DFDFDF;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    height: 50px;
    width: 50px;
    background: #FFFFFF;
}

.shopping-cart-item-quantity-decrease {
    position: relative;
    border-top: solid 1px #DFDFDF;
    border-bottom: solid 1px #DFDFDF;
    border-left: solid 1px #DFDFDF;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    height: 50px;
    width: 50px;
    background: #FFFFFF;
}

.shopping-cart-item-quantity-decrease.disabled, .shopping-cart-item-quantity-increase.disabled {
    background: #DDDDDD;
}

.shopping-cart-item-quantity {
    position: relative;
    border-top: solid 1px #DFDFDF;
    border-bottom: solid 1px #DFDFDF;
    height: 50px;
    width: 50px;
}

.shopping-cart-item-quantity-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    color: #676767;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 0;
}

.shopping-cart-item-error {
    color: #CD5C5C;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    width: 100%;
    text-align: left;
    text-transform: uppercase;
}

.shopping-cart-item-invalid {
    width: 100%;
    height: 40px;
    background: #CD5C5C;
    color: #FFFFFF;
    padding: 5px 15px;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-size: 9px;
    font-weight: 900;
    letter-spacing: 0;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}