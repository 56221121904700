.icon-button {
    width: 100px;
    min-height: 80px;
    border-radius: 12px;
    text-align: center;
    margin: 10px;
    cursor: pointer;
    transition: transform 0.5s ease, background 0.5s ease, filter 0.5s ease;
}

.icon-button:hover {
    filter: brightness(95%);
}

.icon-button-box-shadow {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.261172);
    border-radius: 12px;
}

.icon-button-icon-wrapper {
    width: auto;
    position: relative;
}

.icon-button-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.icon-button-icon-normal {
    width: auto;
}

.icon-button-icon-active {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.icon-button-label {
    width: 100%;
    text-align: center;
    margin-top: 5px;
    transition: color 0.5s ease;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}