.cross-sell-modal {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
}

@media only screen and (max-width: 1000px), (max-height: 850px) {
    .cross-sell-modal {
        padding: 40px;
        box-sizing: border-box;
    }
}

.cross-sell-modal-loader {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.cross-sell-modal-title {
    margin: 0 0 20px 0;
    width: 100%;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.cross-sell-modal-description {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}

.cross-sell-modal-title .bold, .cross-sell-modal-description .bold {
    font-weight: 700;
}

.cross-sell-modal-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.cross-sell-modal-actions {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.cross-sell-modal-action {
    margin: 10px;
}