.product-details-sidebar {
    position: absolute;
    top: 0;
    z-index: 1001;
    width: 320px;
    height: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.22);
    transition: right 0.5s ease, opacity 0.2s ease;
}

.product-details-sidebar-tabs-container {
    width: 100%;
    box-sizing: border-box;
}

.product-details-sidebar-content {
    padding: 20px 25px 0 25px;
    box-sizing: border-box;
    overflow: auto;
}

.product-details-sidebar-content::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.product-details-sidebar-content::-webkit-scrollbar-track {
    display: none;
}

.product-details-sidebar-content::-webkit-scrollbar-thumb {
    display: none;
}

.product-details-sidebar-content::-webkit-scrollbar-thumb:hover {
    display: none;
}

.product-details-sidebar-description {
    width: 100%;
}

.product-details-sidebar-description-content {
    width: 100%;
    overflow-y: auto;
    margin-top: 20px;
}

.product-details-sidebar-features {
    width: 100%;
    height: 100%;
}

.product-details-sidebar-feature {
    display: block;
    padding: 10px 0;
    border-bottom: solid 1px lightgrey;
}

.product-details-sidebar-feature-key {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0;
    text-align: left;
}

.product-details-sidebar-feature-value {
    color: #555555;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
    margin-top: 5px;
}

.product-details-sidebar-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-top: solid 1px rgba(0, 0, 0, 0.05);
}

.product-details-sidebar-action {
    margin: 10px;
}

.product-details-sidebar-error {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.product-details-sidebar-error-message {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0;
}