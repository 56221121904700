.product-box-slider {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.product-box-slider.no-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.product-box-slider.scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.product-box-slider.scrollbar::-webkit-scrollbar-track {
    background: #F1F1F1;
}

.product-box-slider.scrollbar::-webkit-scrollbar-thumb {
    background: #CCCCCC;
}

.product-box-slider.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #AAAAAA;
}