.product-title {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.product-title-first-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.product-title-discount-tag {
    border-radius: 40px;
    padding: 8px 30px;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 5px;
    transition: background 0.5s ease, color 0.5s ease;
}

.product-title-sku {
    padding: 8px 5px;
    box-sizing: border-box;
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
    margin: 5px;
    text-shadow: 2px 2px 2px #FFFFFF;
}

.product-title-second-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.product-title-name {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    margin: 5px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 5px;
    padding-right: 10px;
    box-sizing: border-box;
    text-shadow: 2px 2px 2px #FFFFFF;
}

.product-title-third-line {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.product-title-seller-icon-container {
    margin-right: 6px;
}

.product-title-seller-message {
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #414042;
    text-shadow: 2px 2px 2px #FFFFFF;
    width: 100%;
}

.product-title-seller-name {
    font-weight: bold;
    font-size: 18px;
    transition: color 0.5s ease;
}

.product-title-main-seller-icon-container {
    display: inline-block;
    position: relative;
    top: 5px;
    margin-left: 3px;
}

.product-title-info-delivery {
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #414042;
    width: 100%;
}

.product-title-info-delivery-icon-container {
    width: 25px;
    position: relative;
    top: 5px;
    margin: 0 8px;
}

.product-title-irrelevant-price {
    color: #757575;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 300;
    letter-spacing: 0;
    text-decoration: line-through;
    margin: 5px;
    display: flex;
    text-shadow: 2px 2px 2px #FFFFFF;
}

.product-title-secondary-price {
    color: #414042;
    font-family: 'Lato', sans-serif;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0;
    margin: 5px;
    display: flex;
    text-shadow: 2px 2px 2px #FFFFFF;
}

.product-title-main-price {
    font-family: 'Lato', sans-serif;
    font-size: 45px;
    font-weight: 700;
    letter-spacing: 0;
    margin: 5px;
    display: flex;
    text-shadow: 2px 2px 2px #FFFFFF;
    transition: color 0.5s ease;
}

.product-title-price-card {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.product-title-unit {
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0;
    margin: 5px;
    display: flex;
    text-shadow: 2px 2px 2px #FFFFFF;
    transition: color 0.5s ease;
    color: #999999;
}
