.filter-modal {
    width: 100%;
    max-height: calc(100% - 100px);
    overflow: hidden;
    margin-bottom: 100px;
}

.filter-modal::-webkit-scrollbar {
    width: 0;
    height: 0;
}

@media only screen and (max-width: 768px), (max-height: 600px) {
    .filter-modal {
        padding: 20px;
        box-sizing: border-box;
        overflow: auto;
    }
    .filter-modal::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    .filter-modal::-webkit-scrollbar-track {
        background: #F1F1F1;
    }
    .filter-modal::-webkit-scrollbar-thumb {
        background: #CCCCCC;
    }
    .filter-modal::-webkit-scrollbar-thumb:hover {
        background: #AAAAAA;
    }
}

.filter-modal-header {
    width: 100%;
    min-height: 105px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
}

.filter-modal-title {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    text-transform: uppercase;
    transition: color 0.5s ease;
}

.filter-modal-description {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    margin-top: 15px;
    transition: color 0.5s ease;
}

.filter-modal-header-right-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

@media only screen and (max-width: 768px), (max-height: 600px) {
    .filter-modal-header-right-panel {
        display: none;
    }
}

.filter-modal-header-size-icon {
    width: 100px;
    margin-right: 30px;
}

.filter-modal-header-size-legend-item {
    margin: 0 0 10px 0;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: left;
}

.filter-modal-header-size-legend-item:last-child {
    margin: 0;
}

.filter-modal-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    margin-top: 20px;
}

@media only screen and (max-width: 768px), (max-height: 600px) {
    .filter-modal-content {
        display: block;
        justify-content: unset;
        align-items: unset;
        align-content: unset;
    }
}

.filter-modal-types {
    width: auto;
}

.filter-modal-types-wrapper {
    margin: 0 auto;
    width: 140px;
    display: grid;
}

@media only screen and (max-width: 768px), (max-height: 600px) {
    .filter-modal-types-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}

.filters-modal-brands-options {
    width: 100%;
    height: auto;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: auto;
}

.filters-modal-other-options-container {
    width: 100%;
}

.filters-modal-other-options-title {
    width: 100%;
    padding: 10px 35px;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0;
    color: #111111;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filters-modal-other-options-container:first-child .filters-modal-other-options-title:first-child {
    padding: 0 35px 10px 35px;
}

.filters-modal-other-options {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.filter-modal-options {
    width: 100%;
    height: 634px;
    overflow: auto;
    padding-top: 10px;
}

@media only screen and (max-width: 768px), (max-height: 600px) {
    .filter-modal-options {
        height: auto;
    }
}

.filter-modal-options::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.filter-modal-actions {
    width: 100%;
    height: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 30px;
    box-sizing: border-box;
    box-shadow: 0 -10px 10px -10px rgba(0, 0, 0, 0.207878);
    transition: background 0.5s ease;
}

.filter-modal-action {
    margin: 10px;
    width: auto;
}

.filter-modal-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    text-align: center;
}