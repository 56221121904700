/* Roboto Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Open Sans Font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Lato Font */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --cross-loader-primary-color: unset;
    --cross-loader-secondary-color: unset;
    --modal-close-button-background-color: unset;
    --modal-close-button-label-color: unset;
}

.app {
    width: 100vw;
    height: 100vh;
    user-select: none;
    overflow: auto;
}

.app-version-tag {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9996;
    letter-spacing: 0;
}

.app-loader, .app-error {
    position: relative;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
}

.app-loader-wrapper, .app-error-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.update-alert-container {
    width: 100%;
    height: auto;
    position: fixed;
    top: -1000px;
    left: 50%;
    z-index: 10000;
    transform: translateX(-50%);
    transition: top 0.5s ease;
}

.update-alert-container.visible {
    top: 0;
}

.update-alert-container.hidden {
    top: -1000px;
}