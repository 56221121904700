.category {
    width: 100%;
    margin: 30px auto 0 auto;
    box-sizing: border-box;
}

.category-children {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    overflow-x: auto;
    padding: 20px;
    box-sizing: border-box;
}

.category-children::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.category-children::-webkit-scrollbar-track {
    background: #F1F1F1;
}

.category-children::-webkit-scrollbar-thumb {
    background: #CCCCCC;
}

.category-children::-webkit-scrollbar-thumb:hover {
    background: #AAAAAA;
}

@media only screen and (max-width: 768px) {
    .category {
        padding: 0;
    }
    .category-children {
        flex-wrap: wrap;
        justify-content: center;
    }
    .category-children::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .category-children::-webkit-scrollbar-track {
        display: none;
    }
    .category-children::-webkit-scrollbar-thumb {
        display: none;
    }
    .category-children::-webkit-scrollbar-thumb:hover {
        display: none;
    }
}