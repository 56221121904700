.signup {
    width: 100%;
    height: auto;
}

.signup-loader, .signup-error {
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}